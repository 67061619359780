import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61')
];

export const server_loads = [0,2,4,7,6];

export const dictionary = {
		"/(app)": [~8,[2],[3]],
		"/(app)/admin": [~9,[2,4],[3]],
		"/(app)/admin/application-settings": [10,[2,4],[3]],
		"/(app)/admin/contact": [~11,[2,4],[3]],
		"/(app)/admin/contact/chat/[id]": [~12,[2,4],[3]],
		"/(app)/admin/dropin": [~13,[2,4],[3]],
		"/(app)/admin/dropin/chat/[id]": [~14,[2,4],[3]],
		"/(app)/admin/exercises": [~15,[2,4],[3]],
		"/(app)/admin/exercises/new": [~17,[2,4],[3]],
		"/(app)/admin/exercises/[id]": [~16,[2,4],[3]],
		"/(app)/admin/followed-program": [~18,[2,4],[3]],
		"/(app)/admin/followed-program/new": [~20,[2,4],[3]],
		"/(app)/admin/followed-program/[id]/edit": [~19,[2,4],[3]],
		"/(app)/admin/notifications-history": [~21,[2,4],[3]],
		"/(app)/admin/try-contact": [~22,[2,4],[3]],
		"/(app)/admin/try-contact/chat/[id]": [~23,[2,4],[3]],
		"/(app)/admin/upcoming-events": [~24,[2,4],[3]],
		"/(app)/admin/users": [~25,[2,4],[3]],
		"/(app)/admin/users/[id]": [~26,[2,4],[3]],
		"/(app)/admin/wod-calendar/new": [~28,[2],[3]],
		"/(app)/admin/wod-calendar/[id]": [~27,[2],[3]],
		"/(app)/book-training": [29,[2],[3]],
		"/(app)/calendar": [~30,[2],[3]],
		"/chat/not-found": [60],
		"/chat/[id]": [~59],
		"/(app)/followed-program/[id]": [~31,[2],[3]],
		"/init-password": [~61],
		"/(app)/install-app": [32,[2],[3]],
		"/(auth)/login": [~54,[7]],
		"/(auth)/logout": [55,[7]],
		"/(app)/month-calendar": [33,[2],[3]],
		"/(app)/my-programs": [34,[2],[3]],
		"/(app)/my-programs/[id]": [35,[2],[3]],
		"/(app)/my-workouts": [~36,[2],[3]],
		"/(app)/my-workouts/[id]": [~37,[2],[3]],
		"/(auth)/password/request": [~56,[7]],
		"/(auth)/password/reset": [~57,[7]],
		"/(app)/payment/canceled": [38,[2],[3]],
		"/(app)/payment/success": [~39,[2],[3]],
		"/(app)/personal-best": [~40,[2],[3]],
		"/(app)/personal-best/[id]": [~41,[2],[3]],
		"/(app)/profile/followed-programs": [~42,[2,5],[3]],
		"/(app)/profile/notifications": [~43,[2,5],[3]],
		"/(app)/profile/payments": [~44,[2,5],[3]],
		"/(app)/profile/profile": [~45,[2,5],[3]],
		"/(app)/profile/security": [~46,[2,5],[3]],
		"/(app)/program": [47,[2],[3]],
		"/(auth)/register-90dk3eiozMLKSzhsj": [~58,[7]],
		"/(app)/user-workouts/[id]/finalize": [~48,[2,6],[3]],
		"/(app)/user-workouts/[id]/sections/[sectionId]": [~49,[2,6],[3]],
		"/(app)/user-workouts/[id]/start": [50,[2,6],[3]],
		"/(app)/users/[id]": [~51,[2],[3]],
		"/(app)/workouts": [~52,[2],[3]],
		"/(app)/workouts/[id]": [~53,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';